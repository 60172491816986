<template>
  <div style="width: 100%;height: 100%">
    <router-view v-if="isRouterAlive" :key="$route.fullPath" />
  </div>
</template>

<style>

</style>

<script>

export default {
  name:"App",
  provide(){
    return{
      reload:this.reload,
    }
  },
  data(){
    return {
      isRouterAlive: true
    }
  },
  methods:{
    reload(){
      this.isRouterAlive=false
      this.$nextTick(function (){
        this.isRouterAlive=true
      })
    },
  },
}
</script>
