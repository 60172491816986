import { createRouter, createWebHistory } from 'vue-router'

const routes = [

  {
    path: '/',
    name: 'homePage',
    component: ()=>import("@/views/homePage.vue"),
  },
  {
    path: '/board1',
    name: 'board1',
    component: ()=>import("@/views/boardPage1.vue"),
  },
  {
    path: '/board2',
    name: 'board2',
    component: ()=>import("@/views/boardPage2.vue"),
  },
  {
    path: '/imagePage',
    name: 'imagePage',
    component: ()=>import("@/views/imagePage.vue"),
  },
  {
    path: '/aboutMe',
    name: 'aboutMe',
    component: ()=>import("@/views/aboutMePage.vue"),
  },
  {
    path: '/storyPage',
    name: 'storyPage',
    component: ()=>import("@/views/storyPage.vue"),
  },
  {
    path: '/test',
    name: 'test',
    component: ()=>import("@/views/test"),
  },

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
