import { createApp } from 'vue'
import 'element-plus/dist/index.css'
import ElementPlus from 'element-plus'
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import App from './App.vue'
import router from './router'
import store from './store'
import VueParticles from 'vue-particles'
import $ from 'jquery'
import VueKeepRatio from 'vue-keep-ratio'
import VueLazyload from "vue-lazyload";
import VueVideoPlayer from '@videojs-player/vue'
import 'video.js/dist/video-js.css'

createApp(App).
use(store).
use(VueParticles).
use(router).
use(ElementPlus, {locale: zhCn,size:'small'}).
use($).
use(VueKeepRatio).
use(VueLazyload, {
    preLoad: 1.3,
    error: require('@/assets/loading.gif'),
    loading: require('@/assets/loading.gif'),
    attempt: 1,
}).
use(VueVideoPlayer).
mount('#app')
